//@import '../setting';
@import 'fonts';

@import '../../vendor/bootstrap-3.3.7/dist/css/bootstrap.min';


.container {
  min-width: 320px;
}

@media (min-width: 1200px) {
  .container {
    width: 1196px;
  }
}

@media (max-width: 1200px) {
  .container {
    width: 99%;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

@import '../../vendor/font-awesome-4.7.0/css/font-awesome.min.css';

@import '../../vendor/fancybox-master/dist/jquery.fancybox.min.css';

@import '../angular';


@import 'legacy';

@import 'vars';

.mainpage__h3 {
  margin-top: 0;
}

a {
  &,
  &:hover,
  &:visited,
  &:active {
    //color: $akrilColorLink;
    cursor: pointer;
    text-decoration: none;
  }
}

@import 'icomoon';

//@import 'header';

@import 'navbar';

//@import "sidebar";

@import 'skelet/skelet';
