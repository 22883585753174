$akrilColorBlue: #325194;
$akrilColorBlueLight: #4a659c;

$akrilColorGreen: #a1e142;
$akrilColorGreenDark: #21623a;
$akrilColorGrey: #808080;
$akrilColorGreyLight: #ddd;

$akrilColorGreyLinkPanelButton: #b4b4b4;
$akrilColorGreyLinkPanel: #d6d6d6;


$akrilColorWhite: #fff;
$akrilColorBlack: #000;

$akrilColorLink: #333;

$akrilSidebarItemHeight: 45px;

$akrilProductCardSmallWidth: 175px;
$akrilProductCardSmallFooterHeight: 35px;

$akrilProductCardBigWidth: 100%;

$akrilButtonHeight: 45px;

$akrilBaseBlockShadow: $akrilColorGreyLinkPanel 0 0 8px;