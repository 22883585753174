@mixin new($name, $type: nill) {
  @at-root {
    @if $type != nill {
      .#{$name}-#{$type} {
        @content;
      }
    }
    @else {
      .#{$name} {
        @content;
      }
    }
  }
}

@mixin element($element_name) {
  @at-root #{&}__#{$element_name} {
    @content;
  }
}

@mixin mod($mod) {
  @at-root #{&}.-#{$mod} {
    @content;
  }
}
