@import "vars";

.navbar {
  &.navbar-default {
    background-color: $akrilColorWhite;
  }

  .spec-padding {
    padding-left: 5px;
    padding-right: 0;
  }

  .container > .row {
    padding-top: 2px;
  }

  .row .item-group {
    min-height: 21px;

    .item {
      margin: 0;
    }
  }

  .container {
    background-color: $akrilColorBlue;
    box-shadow: 0 0 8px $akrilColorGreyLinkPanelButton;
    border-radius: 0 0 8px 8px;
    border: 0;
    padding-top: 3px;
    padding-left: 0;
    padding-right: 0;
  }

  .input-group input.form-text {
    /* important because the same in legacy */
    border-radius: 5px !important;
    padding-left: 9px;

    &::placeholder {
      text-transform: uppercase;
    }
  }

  .search-products-button {
    height: 22px;
    margin: 0;
    padding: 0;
    right: 5px;
    width: 22px;
  }

  .__search_btn .form-control,
  .__search_btn .btn {
    height: 25px;
  }

  .col-lg-3 {
    .__search_btn {
      height: 30px;
      margin-top: 0;
    }
  }

  a {
    &,
    :hover,
    :active,
    :link,
    :visited {
      color: $akrilColorWhite;

      .icon {
        color: $akrilColorGreen;
      }
    }

    &:hover span {
      text-decoration: underline;
    }

    cursor: pointer;
  }

  .item-group {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(5, auto);
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
    min-height: 36px;

    &::after,
    &::before {
      display: none;
    }

    > div {
      display: inline-block;
      margin: 4px;
    }
  }

  .fix-nav & {
    left: 50%;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    display: block;

    @media (max-width: 768px) {
      display: none;
    }

  }

  border: 0;
  font-family: Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14.5px;
  margin-bottom: 4px;
  min-height: 30px;
  z-index: 400;
  display: none;
}
