.main-page__comment {
  &::before {
    content: '';
    width: 0;
    position: absolute;
    left: -40px;
    top: 20px;
    z-index: -1;
    height: 0;
    border-left: 0px solid transparent;
    border-top: 20px solid transparent;
    border-right: 40px solid rgb(192 ,192,192);
    border-bottom: 20px solid transparent;
  }
}