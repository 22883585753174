* {
  box-sizing: border-box;
}

@import "../legacy";

body {
  margin: 0;
  padding: 0;
}

.skelet__container {
  font-size: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

@import 'header';

@import 'buttons';

@import 'productCardSmall';

@import 'productCardBig';

@import 'productSpec';

@import 'sidebar';


// by page

@import "main__index";

@import "specialHack";