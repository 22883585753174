@import 'abem';

@import '../vars';

@include new('productSmallCardsWrapper') {
  align-content: flex-start;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  //margin-left: 10px;
  //margin-right: 10px;
  //padding: 10px;
}


@include new('productCardSmall') {
  @include element('badgeWrapper') {
    @include element('badge') {
      background-color: $akrilColorBlueLight;
      border: 0 solid transparent;
      border-radius: 0 8px 8px 0;
      color: $akrilColorWhite;
      display: flex;
      font-size: .75em;
      margin-bottom: 5px;
      padding: 1px;
    }


    left: 0;
    padding-top: 5px;
    position: absolute;
    top: 0;
  }


  @include element('image') {
    height: $akrilProductCardSmallWidth;
    width: $akrilProductCardSmallWidth;
    padding: 4px;
    border-radius: 8px;
  }


  @include element('title') {
    font-size: 16px;
    padding: 3px 7px;

    &, &:active, &:visited, &:hover {
      color: $akrilColorBlack;
    }
  }


  @include element('footer') {
    @include element('price') {
      display: inline-flex;
      font-size: 14px;
      line-height: 1.75;
      padding-left: 7px;
    }


    @include element('barcode') {
      display: inline-flex;
      font-size: 1rem;
      text-align: center;
      width: 55px;
    }


    background-color: $akrilColorBlue;
    color: $akrilColorWhite;
    display: grid;
    align-items: center;
    grid-template-columns: auto 55px;
    min-height: $akrilProductCardSmallFooterHeight;
    justify-content: space-between;
    margin-top: auto;
  }

  &:hover {
    box-shadow: #64cc64 0 0 8px;
  }

  &:hover .productCardSmall__footer {
    background-color: #64cc64;
  }

  box-shadow: $akrilBaseBlockShadow;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  //height: 305px;
  margin: 10px 15px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: $akrilProductCardSmallWidth;
}
