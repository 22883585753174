@import '../vars';

.aside-sidebar {
  border-radius: 8px;
  box-shadow: $akrilBaseBlockShadow;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  max-width: 768px;
  width: 100%;

  > .aside-sidebar__item {
    padding-left: 0;

    > .aside-sidebar__icon {
      color: $akrilColorBlue;
    }
  }
}

.aside-sidebar__general-wrapper {
  background-color: $akrilColorBlue;
  border-radius: 0 0 8px 8px;
  color: $akrilColorWhite;
  display: flex;
  flex-direction: column;
}

.aside-sidebar__general-item-wrapper {
  border-bottom: 1px solid rgb(60, 96, 158);
  display: flex;
  position: relative;
  width: 100%;

  &:nth-last-child(1) {
    border-bottom: 0;
  }

  &.-general {
    position: relative;
  }

  &:hover .aside-sidebar__item.-general {

  }
}

.aside-sidebar__item {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: $akrilSidebarItemHeight;
  line-height: $akrilSidebarItemHeight / 2;
  position: relative;
  width: 100%;

  &.-general {
    padding-left: $akrilSidebarItemHeight;

    .asideSidebar__title {
      color: $akrilColorWhite;
    }

  }

  .aside-sidebar__general-item-wrapper:hover &,
  &:hover,
  &:active {
    text-decoration: none;

    .asideSidebar__title {
      color: $akrilColorBlack;
    }
  }

  &,
  &:hover,
  &:active {
    text-decoration: none;

    .asideSidebar__title {
      color: $akrilColorBlack;
    }
  }

  &.-child {
    border-bottom: 1px solid $akrilColorGreyLight;
    min-width: 210px;
    padding-left: 5px;

    .asideSidebar__title {
      color: $akrilColorBlack;
    }

    &:nth-last-child(1) {
      border-bottom: 0;
    }
  }

  &.-main {
    align-items: center;
    display: grid;
    font-size: 20px;
    grid-column-gap: 3px;
    grid-template-columns: 30px auto;
    justify-items: center;
    text-transform: uppercase;

    & .asideSidebar__title {
      font-size: 75%;
      font-weight: bold;
    }
  }

  &.-news {
    display: grid;
    align-items: start;
    justify-items: center;
    grid-template-columns: 110px auto;
    padding: 10px 0;
    background-color: $akrilColorBlue;
    color: $akrilColorWhite;
    font-size: 16px;
    height: auto;

    border-bottom: 1px solid rgb(60, 96, 158);

    &:nth-last-child(1) {
      border-bottom: 0;
      border-radius: 0 0 8px 8px;
    }

  }
}

.aside-sidebar__item-news--photo {
  width: 90px;
  height: 90px;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
}

.aside-sidebar__item-news-title {
  font-size: 20px;
  color: $akrilColorWhite;

  &:hover, &:active, &:visited {
    color: $akrilColorWhite;
  }
}

.aside-sidebar__item-news-text {

}

.aside-sidebar__item-news--date {
  color: $akrilColorGreen;
}


.aside-sidebar__icon {
  color: $akrilColorGreen;
  margin-right: 3px;

  &.-general {
    display: block;
    left: $akrilSidebarItemHeight / 2;
    position: absolute;
    top: $akrilSidebarItemHeight / 2;
    transform: translate(-50%, -50%);
  }

  .-main > & {
    left: 0;
  }

}

.aside-sidebar__icon-size {
  font-size: 30px;
  line-height: 30px;
}

.aside-sidebar__child-wrapper {
  display: none;
  line-height: $akrilSidebarItemHeight;
}

@media (max-width: 992px) {
  .aside-sidebar__plus-and-minus {
    height: $akrilSidebarItemHeight;
    left: 2px;
    line-height: $akrilSidebarItemHeight;
    position: absolute;
    top: 0;
    width: $akrilSidebarItemHeight;
    z-index: 200;
  }

  .aside-sidebar__icon.-general {
    display: block;
    left: $akrilSidebarItemHeight;
    position: absolute;
    top: $akrilSidebarItemHeight / 2;
    transform: translate(-50%, -50%);
  }

  .aside-sidebar__item {
    &.-general {
      padding-left: $akrilSidebarItemHeight * 1.5;
    }

    &.-child {
      border-bottom: 1px solid $akrilColorGrey;
    }
  }

  .asideSidebar__title {
    color: $akrilColorWhite;
  }

  .aside-sidebar__general-item-wrapper {
    flex-direction: column;
  }

  .aside-sidebar__child-wrapper {
    box-shadow: $akrilBaseBlockShadow;
    color: $akrilColorWhite;
    flex-direction: column;
  }
}






@media (min-width: 992px) {
  .aside-sidebar__plus-and-minus {
    display: none;
  }

  .aside-sidebar__item {
    &.-child {
      padding-left: 10px;

      &:hover {
        background-color: whitesmoke;
      }
    }
  }

  .aside-sidebar__general-item-wrapper {
    position: relative;

    &:hover {
      background-color: $akrilColorWhite;
      color: $akrilColorBlack;

      .aside-sidebar__icon {
        color: $akrilColorBlue;
      }

      .aside-sidebar__child-wrapper {
        background-color: $akrilColorWhite;
        border-radius: 5px;
        box-shadow: 0 0 16px opacify($akrilColorGreyLight , .6);
        color: $akrilColorBlack;
        display: flex;
        flex-direction: column;
        left: 99%;
        overflow: hidden;
        position: absolute;
        top: 1px;
        z-index: 300;
      }
    }
  }
}
