@import 'abem';
@import '../vars';

.header {
  display: flex;

  @media (min-width: 768px) {
    padding-top: 20px;
  }


  flex-direction: column;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 42px;
  font-family: Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;

  input[type=search] {
    //font-family: Georgia;     // Overridden by font: -webkit-small-control;
    border: 0;  // Overridden by border: 2px inset;
    border-radius: 8px 0 0 8px;
    line-height: 35px;           // Irrelevant, I guess
    padding: 3px;            // Overridden by padding: 1px;
  }

  input::placeholder {
    color: $akrilColorGrey;
    text-transform: uppercase;
  }

  input:focus {
    outline: none;
  }

  .button {
    text-transform: uppercase;
  }

  & a:hover span {
    text-decoration: underline;
  }
}

.header__general-panel {

  @media (min-width: 768px) {
    grid-template-columns: 3fr 3fr;
    grid-row-gap: 8px;
    grid-template-rows: auto 112px;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-bottom: 20px;
    justify-items: center;
  }

  @media (min-width: 1050px) {
    grid-template-columns: 3fr 4fr 3fr 2fr;
    grid-template-rows: 112px;

  }

  @media (max-width: 768px) {
    margin: 0 8px;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 23fr 5fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  display: grid;

}

.header__logo {

  @media (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  display: grid;
  grid-template-rows: min-content min-content;
  text-transform: none;
  color: $akrilColorBlue;
  align-content: center;
  padding-left: 10px;
}

.header__logo-img {
  align-self: end;
  width: 100%;
}

.header__logo-svg {
  align-self: start;
}

.header__logo-text {
  width: 100%;
  fill: $akrilColorBlue
}

.header__cta {


  @media (max-width: 768px) {
    display: none;
  }

  align-content: space-between;
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  overflow: hidden;
  width: 100%;
}



.header__cta-compact {
  @media (min-width: 768px) {
    display: none;
  }

  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  align-items: center;

  display: grid;
  grid-template-columns: 23fr 5fr;
  grid-template-rows: 48px auto;

  justify-items: center;

  border-radius: 0 0 8px 8px;
  border: 0;
  box-shadow: $akrilColorGreyLinkPanel 0 0 8px;
  background-color: $akrilColorBlue;

  a,
  a:hover,
  a:active,
  a:visited {
    color: $akrilColorWhite;
    font-size: 1.75rem;
    line-height: 2.5;
  }

}

.header__cta-compact-bars-icon {
  height: 30px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.header_cta-compact-bars-content {
  display: none;
}

.header_cta-compact-bars-content.active {
  grid-column-start: 1;
  grid-column-end: 2;


  padding: 0 0 8px 0;
  align-items: center;
  display: flex;
  flex-direction: column;
}


.header__cta-button {
  background-color: $akrilColorBlue;
  color: $akrilColorWhite;
  align-items: center;
  width: 153px;
  height: 43px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: $akrilBaseBlockShadow;
  overflow: hidden;

  &:visited {
    color: $akrilColorWhite;
  }

  &:hover {
    background-color: $akrilColorWhite;
    color: $akrilColorBlack;
  }

}


.header__cta-title {
  font-size: 14.52px;
  line-height: 18px;
  white-space: nowrap;
}


.header__cta-icon {
  display: inline-flex;
  margin-right: 3px;
  width: 20px;
  height: 30px;
  font-size: 30px;
  line-height: 30px;
  color: $akrilColorGreen;

  &::before {
    margin-left: -10px;
  }

  .header__cta-button:hover & {
    color: $akrilColorBlue
  }

}

.header__contact {


  @media (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    align-items: center;
  }

  //align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
  font-size: 16.67px;
}

.header__contact-item {
  display: inline-flex;
  margin-bottom: 10px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.header__contact-icon {
  font-size: 23px;
  margin-right: 15px;
  color: $akrilColorBlue;
}

.header__contact-prelink {
  margin-right: 10px;
}

.header__contact-link {
  color: $akrilColorBlue;
}


@media (max-width: 768px) {
  .header__cart-title,
  .header__cart-total-price,
  .header__cart-ctp {
    display: none;
  }
}

.header__cart {

  @media (max-width: 768px) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }


  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.header__cart-icon {
  font-size: 45px;
  line-height: 45px;
  color: $akrilColorGreen;
}
.header__cart-link {

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  background-color: $akrilColorBlue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 8px;
  padding: 4px 16px;
  width: 177px;
  height: 112px;
  box-shadow: $akrilBaseBlockShadow;

  &,
  &:hover,
  &:active {
    color: $akrilColorWhite;
  }

}

.header__cart-title {
  font-size: 15px;
}

.header__cart-ctp {
  font-size: 12.5px;
}

.header__cart-total-price {
  color: $akrilColorBlack;
  text-transform: none;
  background-color: white;
  border-radius: 10px;
  padding: 1px 6px;
  height: 20px;
}




.header__search-and-links-panel {

  @media (max-width: 768px) {
    margin: 0 8px;

    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  align-items: center;
  background-color: $akrilColorBlue;
  border-radius: 8px;
  box-shadow: $akrilBaseBlockShadow;
  display: grid;

  @media (min-width: 992px) {

    grid-template-columns: repeat(2, auto);
  }

  margin-bottom: 4px;
  margin-top: 4px;
  min-height: 48px;

  @media (max-width: 992px) {
    grid-template-rows: repeat(2, auto);
    justify-content: center;
    grid-row-gap: 8px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 23fr 5fr;
    justify-content: center;
    grid-column-gap: 8px;
    grid-row-gap: 0;
  }

}

.header__search {
  border-radius: 5px;
  display: inline-flex;
  margin: 0 6.5px;
  width: 98.5%;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    height: 48px;
  }

  @media (min-width: 992px) {
    width: 96.5%
  }

}

.header__search-input {
  align-self: center;
  height: 35px;
  width: 100%;
  font-size: 14px;
  font-family: Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: lighter;
}


.header__search-icon {
  align-self: center;
  background-color: $akrilColorWhite;
  border-radius: 0 8px 8px 0;
  display: inline-flex;
  height: 35px;
  line-height: 35px;
  min-width: 25px;
}

.header__link-panel {
  align-items: center;

  display: grid;
  grid-template-columns: 1fr 0.9fr 1.76fr 1.5fr 1fr 1fr;
  justify-content: space-evenly;


  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 890px) {
    width: 100%;
  }
}
.header__link-bars {
  justify-self: center;

  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: block;
  }
}

.header_link-bars-content {
 display: none;

  @media (min-width: 768px) {
    display: none !important;
  }
}

.header__link-bars-icon {
  height: 30px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.header_link-bars-content.active {
  grid-column-start: 1;
  grid-column-end: 3;

  a,
  a:hover,
  a:active,
  a:visited {
    color: $akrilColorWhite;
    font-size: 1.75rem;
    line-height: 2.25;
  }

  padding: 8px 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
}