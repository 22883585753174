@import 'abem';

@import '../vars';

.button {
  align-items: center;
  background-color: $akrilColorWhite;
  box-shadow: $akrilBaseBlockShadow;
  border-radius: 5px;
  color: $akrilColorBlack;
  display: inline-flex;
  //font-weight: 600;
  height: $akrilButtonHeight;
  margin: 3px;
  min-width: 100px;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }

  @include mod('prim') {
    background-color: $akrilColorBlue;
    color: $akrilColorWhite;
  }

  @include mod('link-panel') {
    background-color: $akrilColorBlue;
    box-shadow: none;
    border: 0;
    color: $akrilColorWhite;
    height: 48px;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    margin: 0 3px;
    text-align: center;

    &.link-panel--link_active {
      background-color: $akrilColorWhite;
      border-radius: 6px;
      color: $akrilColorBlack;
      box-shadow: 0 4px 3px -3px $akrilColorGreyLinkPanelButton,
      0 -4px 3px -3px $akrilColorGreyLinkPanelButton;
    }
  }

  @include mod('buy') {
    background-color: $akrilColorGreenDark;
    color: $akrilColorWhite;
    justify-content: center;
  }


  @include element('title') {
    display: inline-flex;
  }


  @include element('icon') {
    display: inline-flex;
    margin-right: 3px;
  }



}
