@font-face {
  font-family: "icomoon";
  src: url("/assets/css/fonts/icomoon.eot?139ie");
  src: url("/assets/css/fonts/icomoon.eot?139ie#iefix")
      format("embedded-opentype"),
    url("/assets/css/fonts/icomoon.ttf?139ie") format("truetype"),
    url("/assets/css/fonts/icomoon.woff?139ie") format("woff"),
    url("/assets/css/fonts/icomoon.svg?139ie#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icomoon-clearfix:before,
.icomoon-clearfix:after {
  content: " ";
  display: table;
}

.icomoon-clearfix:after {
  clear: both;
}

[class^="icomoon-"],
[class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-c_1:before {
  content: "\e900";
}
.icomoon-c_5:before {
  content: "\e901";
}
.icomoon-c_11:before {
  content: "\e902";
}
.icomoon-c_24:before {
  content: "\e903";
}
.icomoon-c_26:before {
  content: "\e904";
}
.icomoon-c_31:before {
  content: "\e905";
}
.icomoon-c_37:before {
  content: "\e906";
}
.icomoon-c_47:before {
  content: "\e907";
}
.icomoon-c_49:before {
  content: "\e908";
}
.icomoon-c_57:before {
  content: "\e909";
}
.icomoon-c_58:before {
  content: "\e90a";
}
.icomoon-c_62:before {
  content: "\e90b";
}
.icomoon-c_65:before {
  content: "\e90c";
}
.icomoon-c_71:before {
  content: "\e90d";
}
.icomoon-c_72:before {
  content: "\e90e";
}
.icomoon-c_100:before {
  content: "\e90f";
}
.icomoon-c_107:before {
  content: "\e910";
}
.icomoon-c_108:before {
  content: "\e911";
}
.icomoon-c_116:before {
  content: "\e912";
}
.icomoon-c_117:before {
  content: "\e913";
}
.icomoon-c_118:before {
  content: "\e914";
}
.icomoon-c_121:before {
  content: "\e915";
}
.icomoon-c_158:before {
  content: "\e916";
}
.icomoon-cart:before {
  content: "\e917";
}
.icomoon-delivery:before {
  content: "\e918";
}
.icomoon-download:before {
  content: "\e919";
}
.icomoon-mail:before {
  content: "\e91a";
}
.icomoon-ok:before {
  content: "\e91b";
}
.icomoon-payment:before {
  content: "\e91c";
}
.icomoon-phone:before {
  content: "\e91d";
}
.icomoon-ruler:before {
  content: "\e91e";
}
.icomoon-tasklist:before {
  content: "\e91f";
}
.icomoon-watch:before {
  content: "\e920";
}


.icomoon-2x {
  font-size: 2rem;
}

.icomoon-3x {
  font-size: 3rem;
}

.icomoon-2_5x {
  font-size: 2.5rem;
}
