$catalogChildHover: rgba(65, 93, 162, 0.8);
//
//#lightcase-content {
//  box-shadow: none !important;
//  background-color: transparent !important;
//  img {
//    box-shadow: none !important;
//    border-radius: 6px;
//  }
//}

body {
  //margin-bottom: 60px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

html {
  position: relative;
  min-height: 100%;
}

//a {
//  color: #333;
//  &:focus,
//  &:hover {
//    color: #333;
//  }
//}

.h1,
.h2,
.h3,
.h4,
.h5,
.h5,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "PT Sans Narrow", sans-serif !important;
}

h1 {
  margin-top: 20px;
}

//header {
//  position: relative;
//  background: #f6f6f6;
//  border-bottom: 1px solid #f6f6f6;
//  padding: 30px 0 30px;
//  margin-bottom: 25px;
//
//  .logo {
//    padding: 8px 0;
//    text-align: center;
//    p {
//      font-size: 16px;
//      line-height: 30px;
//      color: #415da2;
//    }
//  }
//  .contact {
//    padding: 8px 0;
//    font-family: 'PT Sans Narrow', sans-serif;
//    font-size: 14px;
//    line-height: 1.4;
//    width: 100%;
//    text-transform: uppercase;
//    max-height: 70px;
//    .phone {
//      &-text {
//        color: #707070;
//      }
//      &-number {
//        font-family: Arial, Helvetica, sans-serif;
//        font-size: 18px;
//        color: #555;
//        a:hover {
//          text-decoration: none;
//          color: #ffbf00;
//        }
//      }
//    }
//  }
//  .basket {
//    .top {
//      position: absolute;
//      top: -20px;
//      width: 150px;
//      left: -45px;
//      text-transform: none;
//      font-weight: 600;
//    }
//    .bottom {
//      position: absolute;
//      bottom: -20px;
//      width: 150px;
//      left: -60px;
//      text-transform: none;
//    }
//    padding: 8px 0;
//    font-size: 14px;
//    line-height: 1.4;
//    width: 100%;
//    text-transform: uppercase;
//    max-height: 70px;
//    a {
//      position: relative;
//      float: right;
//      .fa {
//        font-size: 50px;
//        float: left;
//        color: #ccc;
//        margin-top: 0;
//      }
//      .basket-text {
//        white-space: nowrap;
//        position: absolute;
//        right: 20px;
//        top: 5px;
//        border: 3px solid #f6f6f6;
//        background: #ffbf00;
//        display: inline-block;
//        padding: 3px 8px;
//        border-radius: 20px;
//        text-transform: none;
//        font-size: 14px;
//      }
//      &:hover, &:focus {
//        .fa {
//          color: #ffbf00;
//        }
//      }
//    }
//  }
//}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  width: 100%;
  input.form-text {
    border-radius: 35px !important;
    border-color: #e8e8e8;
    box-shadow: none;
    color: #01032a;
    padding: 0 25px;
    font-size: 14px;
  }
  .input-group-addon,
  .input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
  }
  .input-group-addon,
  .input-group-btn,
  .input-group .form-control {
    display: table-cell;
  }
  .input-group-btn {
    .btn {
      margin-left: 5px !important;
      border-radius: 50% !important;
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: #333;
      background-color: #fff;
      border: 1px solid #eaeaea !important;
      padding: 0;
      &:hover,
      &:focus {
        background-color: #ffbf00;
        border-color: #ffbf00 !important;
        color: #333;
      }
    }
  }
}

//.navbar-default .navbar-collapse,
//.navbar-default .navbar-form {
//  border-color: transparent;
//}


//.collapse.in .navbar-nav .open .dropdown-menu li a {
//  padding: 10px 20px;
//  background-color: #415da2 !important;
//  color: #fff !important;
//}
//.navbar-default .navbar-toggle {
//  border-color: #fff;
//  .icon-bar {
//    background-color: #fff;
//  }
//  &:hover,
//  &:focus {
//    border-color: #ffbf00;
//    background-color: transparent;
//    .icon-bar {
//      background-color: #ffbf00;
//    }
//  }
//}

footer {
  margin: 20px 0 0;
  .information {
    padding: 40px 0;
    background: url(../../images/border-gray-top.png) left top repeat-x #f6f6f6;
    h4 {
      color: #222;
      font-size: 24px;
      margin-top: 0;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        a {
          //color: #fff;
          display: inline-block;
          line-height: 18px;
        }
      }
    }
  }
  .copy {
    padding: 20px 0;
    background-color: #334f95;
    span {
      font-size: 12px;
      color: #f4f4f4;
      display: inline-block;
      line-height: 15px;
      a {
        font-size: 12px;
        color: #f4f4f4;
        &:focus,
        &:hover {
          color: #ffbf00;
          text-decoration: none;
        }
      }
    }
  }
}
//
//.wrapper {
//  //padding-bottom: 160px;
//}

//
//header {
//  min-height: 73px;
//  border-bottom: 1px solid #f4f4f4;
//  margin-bottom: 10px;
//  .contact {
//    margin: 8px 0;
//    .phone {
//      line-height: 1.1;
//      &-text {
//        font-size: 16px;
//        font-family: 'PT Sans Narrow', sans-serif;
//        color: #888;
//      }
//      &-number {
//        color: #555;
//        font-family: 'Open Sans' ,sans-serif;
//        font-size: 24px;
//      }
//    }
//  }
//  .basket {
//    padding: 8px 12px;
//    display: table;
//    margin: 8px 0;
//    &-icon {
//      font-size: 30px;
//      max-height: 50px;
//      max-width: 50px;
//      float: left;
//      .fa {
//        display: table-cell;
//        vertical-align: middle;
//      }
//    }
//    &-text {
//      display: table-cell;
//      vertical-align: middle;
//      padding: 0 0 0 10px;
//      line-height: 1.1;
//      &-title {
//        display: block;
//        font-size: 16px;
//        font-family: 'PT Sans Narrow', sans-serif;
//        color: #888;
//      }
//      &-sum {
//        text-transform: uppercase;
//      }
//    }
//    &:hover, &:focus {
//      background: #f4f4f4;
//      text-decoration: none;
//      color: #555;
//    }
//  }
//}
//
//

.breadcrumb {
  padding: 0;
  margin: 0;
  background-color: transparent;
  > li {
    font-size: 13px;
    + li:before {
      content: "\2022";
      padding: 0 5px 0 2px;
    }
    .active {
      color: #333;
    }
    a {
      font-size: 13px;
      color: #999;
    }
  }
}

.alert {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.form-horizontal {
  .control-label {
    font-weight: 400;
  }
}

//
//nav {
//  z-index: 80;
//  .navbar-brand {
//    font-size: 13px;
//    color: #fff !important;
//    padding: 10px 15px;
//    img {
//      width: 100%;
//      height: auto;
//    }
//  }
//  &.navbar {
//    margin-bottom: 0;
//    &.navbar-default {
//      border-radius: 0;
//      border: none;
//      background-color: #334f95;   //#334f95
//      .fixed {
//        display: none;
//      }
//      &.affix {
//        width: 100%;
//        -webkit-box-shadow: 0 5px 10px -3px #555;
//        -moz-box-shadow: 0 5px 10px -3px #555;
//        box-shadow: 0 5px 10px -3px #555;
//        .top {
//          display: none;
//        }
//        .fixed {
//          display: block;
//        }
//      }
//      .navbar-nav {
//        a {
//          color: #fff;
//          font-size: 14px;
//          .fa {
//            padding: 0 5px 0 0;
//          }
//          &:hover {
//            color: #ffbf00;
//            background-color: #415da2;
//          }
//        }
//        > .active {
//          > a {
//            background-color: #415da2;
//            color: #ffbf00;
//          }
//        }
//        .open {
//          a, a:focus, a:hover {
//            background-color: #415da2;
//            color: #ffbf00;
//          }
//          .dropdown-menu {
//            left: 0;
//            right: auto;
//            .divider {
//              background-color: #ccc;
//            }
//            background-clip: border-box;
//            margin-top: 0;
//            width: auto;
//            min-width: 200px;
//            border-color: #ccc;
//            &:after {
//              display: none;
//            }
//            &:before {
//              display: none;
//            }
//            li {
//              &.active {
//                a {
//                  background-color: #444 !important;
//                  color: #ffbf00 !important;
//                  &:hover, &:focus {
//                    background-color: #444 !important;
//                    color: #ffbf00 !important;
//                  }
//                }
//              }
//              a {
//                padding: 10px 20px;
//                background-color: #fff;
//                color: #333;
//                &:hover, &:focus {
//                  background-color: #eee !important;
//                  color: #333 !important;
//                }
//              }
//            }
//          }
//        }
//      }
//    }
//  }
//}
//
//.navbar-form {
//  margin-top: 5px;
//  margin-bottom: 5px;
//  .form-group {
//    position: relative;
//    .icon {
//      position: absolute;
//      font-size: 20px;
//      top: 5px;
//      left: 10px;
//      color: #ccc;
//    }
//    .form-control {
//      border-width: 1px !important;
//      border-color: #415da2 !important;
//      outline: none !important;
//      box-shadow: none !important;
//      min-width: 400px;
//      height: 40px;
//      padding: 6px 12px 6px 35px;
//      &:focus, &:hover {
//        border-color: #415da2 !important;
//        outline: none !important;
//      }
//    }
//  }
//}
//
//.nav-pills {
//  li {
//    &.presentation, &.dropdown-toggle {
//      > a {
//        &:focus, &:hover {
//          color: #333;
//        }
//      }
//      &.active {
//        > a {
//          color: #fff;
//          background-color: #415da2;
//          &:focus, &:hover {
//            color: #fff;
//            background-color: #415da2;
//          }
//        }
//      }
//    }
//  }
//}
//

.dropdown-menu {
  left: 0;
  right: auto;
  .divider {
    background-color: #eee;
  }
  background-clip: border-box;
  width: 100%;
  min-width: 200px;
  border-color: #ccc;
  margin-top: 6px;
  &:after {
    display: none;
  }
  &:before {
    display: none;
  }
  li {
    &.active {
      a {
        background-color: #444 !important;
        color: #fff !important;
        &:hover,
        &:focus {
          background-color: #444 !important;
          color: #fff !important;
        }
      }
    }
    a {
      padding: 10px 20px;
      background-color: #fff;
      color: #333;
      &:hover,
      &:focus {
        background-color: #eee !important;
        color: #333 !important;
      }
    }
  }
}

.dropdown-menu:before {
  position: absolute;
  top: -7px;
  left: 8px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  content: "";
}

.dropdown-menu:after {
  position: absolute;
  top: -6px;
  left: 9px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

$checkbox-width: 18px;
input[type="checkbox"] {
  position: absolute;
  left: -9999px;
  & + label {
    position: relative;
    display: inline-block;
    padding: 6px 10px 6px ($checkbox-width + 10);
    cursor: pointer;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    &:before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin: (-$checkbox-width/2) 0 0;
      width: $checkbox-width;
      height: $checkbox-width;
      //background: url(../images/app-icons.png) no-repeat;
    }
  }
  &:disabled {
    & + label {
      cursor: not-allowed;
      &:before {
        background-position: (-$checkbox-width * 2) 0;
      }
    }

    &:checked + label {
      &:before {
        background-position: (-$checkbox-width * 3) 0;
      }
    }
  }
  &:checked + label {
    &:before {
      background-position: -$checkbox-width 0;
    }
  }
}

///* .category */
//.category {
//  &-child {
//    position: relative;
//    margin: 5px 0;
//    padding: 10px;
//    border: 2px solid #f4f4f4;
//    border-radius: 6px;
//    min-height: 280px;
//    &-image {
//      img {
//        width: 100%;
//        height: auto;
//      }
//    }
//    &-name {
//      font-size: 14px;
//      padding: 5px 0;
//      line-height: 1.3;
//      a {
//        color: #333;
//        &:hover {
//          color: #333;
//        }
//      }
//    }
//    &-abs {
//      position: absolute;
//      bottom: 10px;
//      left: 10px;
//      width: 100%;
//      &-count {
//        font-size: 11px;
//        padding: 3px 0 0;
//        color: #999;
//      }
//    }
//    &:hover {
//      border-color: $catalogChildHover;
//    }
//  }
//  &-description {
//    border-top: 1px solid #f4f4f4;
//    padding: 25px 0 0;
//    margin: 25px 0 0;
//    &-cover {
//      img {
//        border: 1px solid #f4f4f4;
//      }
//    }
//    &-text {
//    }
//  }
//}
///* product */
//.product {
//  &-cover {
//    //top: 20px;
//    height: auto;
//    position: relative;
//    img {
//      max-width: 100%;
//      height: auto;
//      border: 1px solid #f4f4f4;
//    }
//    &-show {
//      position: absolute;
//      left: 20px;
//      bottom: 20px;
//      font-size: 40px;
//      color: #888;
//      &:hover {
//        color: #888;
//      }
//    }
//  }
//  &-cart {
//    position: relative;
//    min-height: 470px;
//    &-uslug {
//      a {
//        color: #f0a100;
//      }
//    }
//    h1 {
//      font-size: 30px;
//    }
//    &-services {
//      border-bottom: 1px solid #f4f4f4;
//      padding: 10px 0;
//      margin-bottom: 10px;
//      width: 100%;
//      span {
//        margin-top: 5px;
//        font-size: 14px;
//        font-style: italic;
//        font-weight: 400;
//        line-height: 1;
//        color: #415da2;
//      }
//      .fa {
//        color: #415da2;
//        margin-right: 15px;
//        margin-top: -2px;
//      }
//      .fa-border {
//        border-color: #415da2;
//        border-radius: 6px;
//      }
//    }
//    &-article {
//      display: inline-block;
//      padding-bottom: 10px;
//      span {
//        font-size: 12px;
//        color: #555;
//      }
//    }
//    &-description {
//      margin-bottom: 15px;
//    }
//    &-images {
//      width: 100%;
//      position: absolute;
//      bottom: 10px;
//      left: 0;
//      img {
//        width: 100%;
//        height: auto;
//      }
//      .row {
//        margin: 0 -5px;
//        .col-xs-2 {
//          padding: 0 5px;
//        }
//      }
//      &-title {
//        font-size: 18px;
//        font-family: "PT Sans Narrow", sans-serif;
//      }
//      img {
//        margin-top: 10px;
//        border: 1px solid #f4f4f4;
//      }
//    }
//  }
//  &-offers {
//    margin-top: 15px;
//  }
//}
//
///* products */
//.products {
//  &-element {
//    position: relative;
//    margin: 5px 0;
//    padding: 10px;
//    border: 2px solid #f4f4f4;
//    border-radius: 6px;
//    min-height: 310px;
//    &-image {
//      img {
//        width: 100%;
//        height: auto;
//      }
//    }
//    &-name {
//      font-size: 14px;
//      padding: 3px 0 0;
//      line-height: 1.3;
//      a {
//        color: #333;
//        &:hover {
//          color: #333;
//        }
//      }
//    }
//    &-abs {
//      position: absolute;
//      bottom: 10px;
//      left: 10px;
//      width: 100%;
//      &-article {
//        padding: 3px 0 0;
//        span {
//          font-size: 11px;
//          color: #fff;
//          padding: 3px 8px;
//          background: #415da2;
//          border-radius: 3px;
//        }
//      }
//      &-price {
//        font-family: "PT Sans Narrow", sans-serif;
//        padding: 10px 0 0;
//        font-size: 18px;
//        font-weight: 700;
//      }
//    }
//    &:hover {
//      border-color: $catalogChildHover;
//    }
//  }
//}

/* pagination */
.products-paginate {
  margin-top: 10px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;

  > {
    li {
      display: inline;

      > {
        a,
        span {
          position: relative;
          float: left;
          padding: 6px 12px;
          line-height: 1.428571429;
          text-decoration: none;
          background-color: #ffffff;
          border: 1px solid #dddddd;
          margin-left: -1px;
        }
      }

      &:first-child > {
        a,
        span {
          margin-left: 0;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
      }

      &:last-child > {
        a,
        span {
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      > {
        a:hover,
        span:hover,
        a:focus,
        span:focus {
          background-color: #b4b4b4;
        }
      }
    }

    .active > {
      a,
      span,
      a:hover,
      span:hover,
      a:focus,
      span:focus {
        z-index: 2;
        color: #ffffff;
        background-color: #415da2;
        border-color: #415da2;
        cursor: default;
      }
    }

    .disabled > {
      span {
        color: #999999;
        background-color: #ffffff;
        border-color: #dddddd;
        cursor: not-allowed;
      }

      a {
        color: #999999;
        background-color: #ffffff;
        border-color: #dddddd;
        cursor: not-allowed;

        &:hover,
        &:focus {
          color: #999999;
          background-color: #ffffff;
          border-color: #dddddd;
          cursor: not-allowed;
        }
      }
    }
  }
}
.pagination {
  margin: 10px 0;

  > {
    li > {
      a,
      span {
        color: #222222;
      }

      a:hover,
      span:hover,
      a:focus,
      span:focus {
        background: #f6f6f6;
      }
    }

    .active > {
      a {
        color: #fff;
        background-color: #415da2;
        border-color: #415da2;
        cursor: default;

        &:hover,
        &:focus {
          color: #fff;
          background-color: #415da2;
          border-color: #415da2;
          cursor: default;
        }
      }

      span {
        color: #fff;
        background-color: #415da2;
        border-color: #415da2;
        cursor: default;

        &:hover,
        &:focus {
          color: #fff;
          background-color: #415da2;
          border-color: #415da2;
          cursor: default;
        }
      }
    }
  }
}

/* Main */
.main {
  .category {
    &-child {
      min-height: 260px;
    }
  }
  &-description {
    margin-top: 25px;
    border-top: 1px solid #f4f4f4;
  }
}

.form-control {
  &:focus {
    border-color: #ffbf00;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(255, 191, 0, 0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(255, 191, 0, 0.6);
  }
}

.btn {
  position: relative;
  .fa {
    position: absolute;
    font-size: 40px;
    left: -5px;
    bottom: -5px;
    padding-right: 10px;
    color: #f6f6f6;
  }
}

.btn {
  &:focus,
  &:active {
    outline: none;
  }
  &-primary {
    color: #fff;
    background-color: #415da2;
    border-color: #415da2;
    &:focus,
    &:hover {
      color: #444;
      background-color: #ffbf00;
      border-color: #ffbf00;
    }
  }
  &-warning {
    background-color: #ffbf00;
    color: #333;
    &:focus,
    &:hover {
      color: #333;
    }
  }
  &-link {
    color: #444;
    &:focus,
    &:hover {
      color: #415da2;
    }
  }
}

.form-group {
  position: relative;
  a {
    &.show-password-eye {
      position: absolute;
      background: url(../../images/input-password-eye.png) 0 -14px no-repeat;
      width: 25px;
      height: 20px;
      right: 25px;
      top: 10px;
      outline: none;
      &.active {
        top: 10px;
        background-position: 0 0;
      }
    }
  }
}

table {
  &.offers {
    display: none;
  }
  &.basket {
    tr {
      td {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  width: 100%;
  padding: 0;
}
table tr {
  background-color: white;
  margin: 0;
  padding: 0;
}
table tr th {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #222;
  text-align: center;
  margin: 0;
  padding: 7px 13px;
}
table tr td {
  border: 1px solid #d8d8d8;
  text-align: center;
  margin: 0;
  padding: 4px 13px;
  font-size: 13px;
  .form-control {
    height: 25px;
    text-align: center;
    padding: 1px 10px;
    font-size: 12px;
    line-height: 1;
    border-radius: 3px;
  }
  .btn {
    height: 25px;
    padding: 1px 10px;
    font-size: 12px;
    line-height: 1;
    border-radius: 3px;
  }
  &.quantity,
  &.buy,
  &.price {
    width: 8em;
  }
  &.price {
    width: 10em;
  }
}
table tr th :first-child,
table tr td :first-child {
  margin-top: 0;
}
table tr th :last-child,
table tr td :last-child {
  margin-bottom: 0;
}

table {
  &.basket {
    margin: 10px 0;
    tr {
      td {
        vertical-align: top;
        padding: 10px 8px;
        &.id {
          width: 2em;
        }
        &.title {
          font-size: 16px;
          .offers {
            &-properties {
              ul {
                font-size: 12px;
                color: #555;
                list-style: none;
                margin: 10px 0 0;
                padding: 0;
                li {
                  //display: inline-block;
                  //margin-right: 5px;
                }
              }
            }
          }
        }
        &.model {
          width: 6em;
        }
        &.article {
          width: 6em;
        }
        &.amount {
          width: 8em;
          .form-control {
            height: 25px;
            text-align: center;
            padding: 1px 10px;
            font-size: 12px;
            line-height: 1;
            border-radius: 3px;
          }
        }
        &.price {
          width: 12em;
        }
        &.total_amount {
          width: 12em;
        }
        &.del {
          width: 2em;
        }
        &.total {
          padding: 15px 30px;
          background: #f4f4f4;
          text-align: right;
          font-size: 16px;
        }
      }
    }
  }
}
.cart {
  .cart-list {
    width: 100%;
    margin: 15px 0;
    thead {
      tr {
        th {
          border: 1px solid #eee;
          //font-family: 'PT Sans Narrow', sans-serif;
          font-size: 12px;
          font-weight: 300;
          text-align: center;
          padding: 10px 0;
          background-color: #eee;
          color: #333;
        }
      }
    }
    tbody {
      tr {
        height: 1em;
        border-bottom: 1px solid #eee;
        td {
          text-align: center;
          padding: 5px 10px;
          border-right: 1px solid #eee;
          border-left: 1px solid #eee;
          &.cart-table-image {
            max-width: 100px;
          }
          &.cart-table-name {
            vertical-align: top;
            text-align: left;
            a {
              font-size: 16px;
            }
            .cart-table-name-attributes {
              list-style: none;
              ul {
                padding: 0;
                margin: 5px 0 0 0;
                li {
                  display: inline-block;
                  margin: 0 10px 0 0;
                  color: #555;
                  font-size: 12px;
                  span {
                    font-weight: 600;
                  }
                }
              }
            }
          }
          &.cart-table-amount {
            input {
              display: inline-block;
              width: 6em;
              -moz-appearance: textfield;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
          &.cart-table-delete {
            a {
              font-size: 20px;
            }
          }
          &.cart-table-total {
            width: 9em;
          }
        }
      }
    }
  }
}

.modal {
  top: 100px;
  &-content {
    border-color: transparent;
  }
  &-dialog {
    width: 400px;
  }
  &-header {
    border-color: transparent;
    h4 {
      font-size: 28px;
    }
  }
  &-footer {
    text-align: left;
    border-color: transparent;
  }
}

.news {
  &-item {
    margin-bottom: 20px;
    min-height: 410px;
    &-image {
      img {
        width: 100%;
        height: auto;
      }
    }
    &-date {
      font-size: 12px;
      color: #707070;
      padding: 10px 0;
    }
    &-title {
      a {
        font-size: 16px;
      }
    }
    &-text {
      padding: 10px 0;
    }
  }
  &-list {
    &-item {
      margin-bottom: 25px;
      padding-bottom: 10px;
      //border-bottom: 1px solid #f4f4f4;
      &-image {
        img {
          width: 100%;
          height: auto;
        }
      }
      &-date {
        font-size: 12px;
        color: #707070;
      }
      &-title {
        a {
          font-size: 16px;
        }
      }
      &-text {
        padding: 10px 0;
      }
    }
    &-header {
      margin-bottom: 5px;
      .h2 {
        display: inline-block;
        margin-right: 10px;
      }
      &-all {
        padding-top: 27px;
        font-size: 14px;
        text-align: left;
        color: #707070;
        &:hover,
        &:focus {
          color: #707070;
        }
      }
    }
  }
}
.article {
  &-item {
    margin-bottom: 30px;
    min-height: 440px;
    &-image {
      img {
        width: 100%;
        height: auto;
      }
    }
    &-title {
      margin-top: 10px;
      a {
        font-size: 16px;
      }
    }
    &-text {
      padding: 10px 0;
    }
  }
  &-list {
    margin-top: 10px;
    margin-bottom: 20px;
    &-header {
      //border-top: 2px dashed #f4f4f4;
      margin-bottom: 5px;
      .h2 {
        display: inline-block;
        margin-right: 10px;
      }
      &-all {
        font-size: 14px;
        text-align: left;
        color: #707070;
        &:hover,
        &:focus {
          color: #707070;
        }
      }
    }
    &-item {
      position: relative;
      margin-bottom: 15px;
      &-image {
        img {
          width: 100%;
          height: auto;
        }
      }
      &-title {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 15px;
        background: rgba(0, 0, 0, 0.7);
        width: 100%;
        -webkit-border-bottom-right-radius: 6px;
        -webkit-border-bottom-left-radius: 6px;
        -moz-border-radius-bottomright: 6px;
        -moz-border-radius-bottomleft: 6px;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        a {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
}

.v {
  &-bottom {
    display: table-cell;
    vertical-align: bottom;
    float: none;
  }
}

.__search_btn {
  margin-top: 25px;
  .form-control,
  .btn {
    height: 40px;
    max-height: 40px;
  }
  .btn {
    font-size: 14px;
    line-height: 30px;
    border: none !important;
    font-family: "PT Sans Narrow", sans-serif;
    text-transform: uppercase;
    padding-left: 25px;
  }
}

.search {
  &-row {
    position: relative;
    .form-control {
      border: 1px solid #ccc;
      box-shadow: none !important;
      &:focus {
        box-shadow: none;
        border-color: #f0a100;
      }
    }
  }
  &-products {
    position: relative;
    &-button {
      position: absolute;
      top: 2px;
      right: 25px;
      border-color: transparent;
      background: transparent;
      font-size: 20px;
      color: #ccc;
      z-index: 2;
      &:hover,
      &:focus {
        outline: none;
        color: #ccc;
      }
    }
    &-list {
      position: absolute;
      float: left;
      background-color: #fff;
      border: 1px solid #cccccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-width: 0 1px 1px;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      left: 12px;
      top: 100%;
      right: auto;
      background-clip: border-box;
      margin: 0px 10px 0 10px;
      width: 600px;
      max-width: 600px;
      border-color: #ccc;
      overflow-y: auto;
      max-height: 500px;
      z-index: 2;
      &-image {
        img {
          width: 100%;
          height: auto;
        }
      }
      &-title {
        a {
          font-size: 14px;
        }
      }
      &-category {
        margin-top: 5px;
        a {
          font-style: italic;
        }
      }
      &-offers {
        span {
          font-style: italic;
        }
      }
      &-article {
        margin-top: 5px;
        span {
          font-size: 11px;
          color: #fff;
          padding: 3px 8px;
          background: #415da2;
          border-radius: 3px;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          padding: 10px 20px;
          border-bottom: 1px solid #f4f4f4;
        }
      }
    }
  }
}

.col-xs-3-5 {
  width: 20%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.well {
  background: #f4f4f4;
  padding: 20px !important;
  border-radius: 0;
  border-color: transparent;
  box-shadow: none;
}

[v-cloak] {
  display: none;
}
.v-cloak {
  &-hidden {
    display: none;
  }
  &-inline {
    display: inline;
    &-block {
      display: inline-block;
    }
  }
  &-block {
    display: block;
  }
}

@media (max-width: 767px) {
  .product-cover {
    height: auto;
    margin-bottom: 20px;
  }
  .product-cart {
    //height: auto;
    min-height: auto;
  }
}

.table-responsive {
  border: none;
  input {
    width: 50px;
  }
  td {
    white-space: nowrap;
  }
}

table {
  width: 99.99999%;
}

body > .adminBar {
  margin-top: 41px;
}
.adminBar {
  .navbar {
    border-radius: 0;
    min-height: auto;
  }
  .navbar-inverse .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

#map {
  &-izhevsk,
  &-moscow {
    height: 350px;
  }
}

/*
.nav {
  &-catalog {
    border: transparent;
    box-shadow: none;
    border-bottom: 1px solid #f4f4f4;
    &__row {
      &_title {
        position: relative;
        display: block;
        background-color: #ffbf00;
        color: #333;
        padding: 10px 12px;
        -webkit-border-top-left-radius: 4px;
        -webkit-border-top-right-radius: 4px;
        -moz-border-radius-topleft: 4px;
        -moz-border-radius-topright: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-size: 16px;
        line-height: 30px;
        border: none !important;
        font-family: 'PT Sans Narrow', sans-serif;
        text-transform: uppercase;
        padding-left: 45px;
        cursor: pointer;
        &.collapsed {
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
        }
        &:hover, &:focus {
          background-color: #ec971f;
          text-decoration: none;
        }
        .fa {
          position: absolute;
          font-size: 40px;
          left: -5px;
          bottom: -5px;
          padding-right: 10px;
          color: #fff;
        }
      }
      .subcat {
        border-left: 1px solid #f4f4f4;
        border-right: 1px solid #f4f4f4;
        padding-bottom: 10px;
      }
      &_el {
        min-height: 40px;
        display: block;
        background-color: transparent;
        color: #333;
        padding: 8px 15px;
        font-size: 14px;
        border-top: 1px solid #f4f4f4;
        border-left: 1px solid #f4f4f4;
        border-right: 1px solid #f4f4f4;
        &:last-child {
          border-bottom: 1px solid #f4f4f4;
        }
        &.sub {
          position: relative;
          padding: 5px 20px 5px 5px;
          margin-left: 40px;
          min-height: 30px;
          border: none;
          //list-style-type: circle;
          display: list-item;
          &:focus, &:hover {
            color: #ec971f;
            background-color: transparent;
            border-color: transparent;
            text-decoration: none;
          }
        }
        &:focus, &:hover {
          background-color: #f4f4f4;
          text-decoration: none;
        }
        &.active {
          color: #ec971f;
          background-color: transparent;
          border-color: transparent;
          &:focus, &:hover {
            color: #ec971f;
            background-color: transparent;
            border-color: transparent;
            text-decoration: none;
          }
          .subcat {
            display: block !important;
          }
        }
      }
    }
  }
}
*/
//
//.personally,
//.delivery,
//.brand {
//  text-align: center;
//  span {
//    display: block;
//    margin-top: 5px;
//  }
//}
//.cover {
//  &-prev {
//    img {
//      width: 100%;
//      height: auto;
//      margin-bottom: 15px;
//    }
//  }
//}
//.product-cart-images-title {
//  margin: 15px 0;
//  font-size: 18px;
//  font-family: "PT Sans Narrow", sans-serif;
//}


.studiof1 {
  color: #fff;
  a {
    color: #fff;
  }
  .pulse {
    position: relative;
    top: -1px;
    animation: pulse 2s infinite;
  }
}
