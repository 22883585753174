////  cyrillic-ext
//@font-face {
//  font-family: 'PT Sans Narrow';
//  font-style: normal;
//  font-weight: 400;
//  src: local('PT Sans Narrow'), local('PTSans-Narrow'),
//    url(https://fonts.gstatic.com/s/ptsansnarrow/v7/UyYrYy3ltEffJV9QueSi4ZEk0SAHyDN38O8i9vCnTn4.woff2)
//      format('woff2');
//  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
//}
////  cyrillic
//@font-face {
//  font-family: 'PT Sans Narrow';
//  font-style: normal;
//  font-weight: 400;
//  src: local('PT Sans Narrow'), local('PTSans-Narrow'),
//    url(https://fonts.gstatic.com/s/ptsansnarrow/v7/UyYrYy3ltEffJV9QueSi4TBlyDWpEJYVQuuPQMv_c-4.woff2)
//      format('woff2');
//  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//}
////  latin-ext
//@font-face {
//  font-family: 'PT Sans Narrow';
//  font-style: normal;
//  font-weight: 400;
//  src: local('PT Sans Narrow'), local('PTSans-Narrow'),
//    url(https://fonts.gstatic.com/s/ptsansnarrow/v7/UyYrYy3ltEffJV9QueSi4awvjhBeOB25B0pWmvErI1g.woff2)
//      format('woff2');
//  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
//    U+A720-A7FF;
//}
////  latin
//@font-face {
//  font-family: 'PT Sans Narrow';
//  font-style: normal;
//  font-weight: 400;
//  src: local('PT Sans Narrow'), local('PTSans-Narrow'),
//    url(https://fonts.gstatic.com/s/ptsansnarrow/v7/UyYrYy3ltEffJV9QueSi4SXGGgjhbil4nYG1ct5o924.woff2)
//      format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
//    U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}
////  cyrillic-ext
//@font-face {
//  font-family: 'PT Sans Narrow';
//  font-style: normal;
//  font-weight: 700;
//  src: local('PT Sans Narrow Bold'), local('PTSans-NarrowBold'),
//    url(https://fonts.gstatic.com/s/ptsansnarrow/v7/Q_pTky3Sc3ubRibGToTAYtUWWqxCFjd5cEd_RrOHL6Y.woff2)
//      format('woff2');
//  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F;
//}
////  cyrillic
//@font-face {
//  font-family: 'PT Sans Narrow';
//  font-style: normal;
//  font-weight: 700;
//  src: local('PT Sans Narrow Bold'), local('PTSans-NarrowBold'),
//    url(https://fonts.gstatic.com/s/ptsansnarrow/v7/Q_pTky3Sc3ubRibGToTAYupmode525gDTwNyPqZGNXY.woff2)
//      format('woff2');
//  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//}
////  latin-ext
//@font-face {
//  font-family: 'PT Sans Narrow';
//  font-style: normal;
//  font-weight: 700;
//  src: local('PT Sans Narrow Bold'), local('PTSans-NarrowBold'),
//    url(https://fonts.gstatic.com/s/ptsansnarrow/v7/Q_pTky3Sc3ubRibGToTAYtglsoc-tKu3skzGCjmMb84.woff2)
//      format('woff2');
//  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F,
//    U+A720-A7FF;
//}
//// latin
//@font-face {
//  font-family: 'PT Sans Narrow';
//  font-style: normal;
//  font-weight: 700;
//  src: local('PT Sans Narrow Bold'), local('PTSans-NarrowBold'),
//    url(https://fonts.gstatic.com/s/ptsansnarrow/v7/Q_pTky3Sc3ubRibGToTAYkBqNlhjGh6uyjdvfMwxzYs.woff2)
//      format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
//    U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
//}


* {
  font-family: Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
}