@import 'abem';

@import '../vars';

@include new('productCardBig') {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 55px;
  width: 100%;

  @include element('imageGallery') {
    box-shadow: $akrilBaseBlockShadow;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 350px;
    min-width: 350px;
    overflow: hidden;
    position: relative;
    width: 350px;

    @include element('uiWrapper') {

      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      overflow: hidden;
      padding-top: 10px;
      position: absolute;
      width: 100%;

      @include element('currentImageZoom') {
        color: $akrilColorGrey;
        display: flex;
        margin-left: 10px;

        &:hover,
        &:active,
        &:visited,
        &:focus {
          color: $akrilColorGrey;
          text-decoration: none;
        }
      }


      @include element('gallerySlider') {
        align-items: center;
        align-self: flex-end;
        display: flex;
        margin-bottom: 15px;
        margin-right: 20px;
      }


      @include element('pictureItem') {
        background-color: opacify($akrilColorWhite, .8);
        box-shadow: #555 0 0 8px;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        height: 50px;
        margin-left: 5px;
        width: 50px;
      }
    }


    @include element('backPicture') {
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      z-index: -100;
    }
  }


  @include element('sidebar') {
    color: $akrilColorWhite;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-top: 10px;
    z-index: 200;

    @include element('item') {
      align-items: center;
      background-color: $akrilColorBlue;
      border-radius: 0 8px 8px 0;
      box-shadow: $akrilColorGreyLight 0 0 4px;
      display: flex;
      height: 52px;
      justify-content: space-between;
      margin-bottom: 13px;
      width: 150px;
      cursor: pointer;

      &:nth-child(1) {
        background-color: $akrilColorGreenDark;
      }

      @include element('text') {
        display: inline-flex;
        line-height: 1.25;
        padding-left: 12px;
        width: 105px;
        font-size: 16.67px;
      }


      @include element('icon') {
        display: inline-flex;
        line-height: 45px;
        text-align: center;
        width: 45px;
      }
    }
  }


  @include element('story') {
    display: flex;
    flex-direction: column;
    justify-self: stretch;
    max-width: 337px;

    @include element('barcode') {
      color: $akrilColorGrey;
      display: flex;
      font-size: 13px;
    }


    @include element('text') {
      display: block;
      font-size: 13px;
    }


    @include element('offer') {
      @include element('button') {

      };
      font-size: 20.83px;
      display: flex;
      margin-top: auto;
    }
  }
}
