@import 'abem';

@import '../vars';

@include new('productSpec') {
  @include element('card') {
    @include element('icon') {
      background-color: $akrilColorBlue;
      border-radius: 50%;
      color: $akrilColorWhite;
      display: inline;
      font-size: 30px;
      height: 50px;
      line-height: 50px;
      margin-right: 5px;
      min-width: 50px;
      overflow: hidden;
    }


    @include element('text') {
      display: inline-flex;
      line-height: 1.5;
      font-size: 1.5rem;
      margin: 0 5px;
      padding: 0;
    }


    align-items: center;
    display: flex;
    height: 50px;
    max-width: 230px;
    text-align: center;

    @media (max-width: 768px) {
      text-align: left;
    }

  }


  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    justify-content: left;
  }
}

